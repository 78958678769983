'use client';

import clsx from 'clsx';
import Link from 'next/link';

import SendLogOnClickWrapper from '@/components/SendLogOnClickWrapper';
import { Brand } from '@/types/brand';

import PartnerLogo from '../SVG/Logo/PartnerLogo';
import PhotoPartnerLogo from '../SVG/Logo/PhotoPartnerLogo';
import RedPictaLogo from '../SVG/Logo/RedPictaLogo';
import WalgreensOnlyLogo from '../SVG/Logo/WalgreensOnlyLogo';

import styles from './styles.module.scss';

export interface LogoProps {
  disabled?: boolean;
  brand: Brand;
}

interface BrandLogoProps {
  brand: Brand;
}

function BrandLogo({ brand }: BrandLogoProps) {
  switch (brand) {
    case Brand.NO_BRAND:
      return null;
    case Brand.WALGREENS:
      return (
        <>
          <WalgreensOnlyLogo />
          <div className={styles.spacer} />
          <PhotoPartnerLogo />
        </>
      );
    default:
      return (
        <>
          <RedPictaLogo />
          <div className={styles.spacer} />
          <PartnerLogo />
        </>
      );
  }
}

export default function Logo({ brand, disabled }: LogoProps) {
  return (
    <SendLogOnClickWrapper event="ui_header_logo_clicked">
      <Link
        href="/"
        className={clsx(
          styles.logo,
          styles[process.env.NEXT_PUBLIC_PARTNER || ''],
          brand === Brand.WALGREENS && styles.walgreensBrand,
          disabled && styles.disabled,
        )}
        aria-label={`${process.env.NEXT_PUBLIC_PARTNER} homepage`}
      >
        <BrandLogo brand={brand} />
      </Link>
    </SendLogOnClickWrapper>
  );
}

import { SVGProps } from 'react';
import clsx from 'clsx';

import styles from './styles.module.scss';

export default function PhotoPartnerLogo({
  className,
  ...props
}: SVGProps<SVGSVGElement>) {
  return (
    <svg
      viewBox="0 0 105 12"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={clsx(styles.color, className)}
      {...props}
    >
      <path
        d="M6.67936 1.59432C7.29513 1.85208 7.81976 2.28806 8.18587 2.84626C8.52894 3.43268 8.70976 4.09985 8.70976 4.77926C8.70976 5.45866 8.52894 6.12583 8.18587 6.71225C7.82151 7.27334 7.29651 7.71161 6.67936 7.96988C5.95057 8.27616 5.1653 8.42506 4.37497 8.40683H2.07437V11.3476H0.174622V1.15738H4.37497C5.16523 1.13994 5.95034 1.28881 6.67936 1.59432ZM6.16453 6.28101C6.36694 6.08734 6.52802 5.85469 6.63807 5.59708C6.74812 5.33947 6.80485 5.06224 6.80485 4.7821C6.80485 4.50197 6.74812 4.22474 6.63807 3.96713C6.52802 3.70952 6.36694 3.47687 6.16453 3.2832C5.61838 2.8964 4.95522 2.71047 4.28758 2.75697H2.07437V6.80344H4.28758C4.95491 6.8497 5.61779 6.66452 6.16453 6.27911"
        fill="currentColor"
      />
      <path
        d="M17.3408 4.34479C17.6553 4.68601 17.8964 5.08821 18.049 5.52644C18.2016 5.96468 18.2625 6.4296 18.228 6.89236V11.3454H16.41V7.12413C16.4512 6.56991 16.2793 6.02087 15.9293 5.58913C15.7507 5.41081 15.5362 5.27252 15.3 5.18343C15.0639 5.09435 14.8115 5.0565 14.5596 5.0724C14.2677 5.05711 13.9758 5.10294 13.7027 5.20697C13.4295 5.311 13.1811 5.47093 12.9733 5.67652C12.5569 6.15689 12.3472 6.7824 12.3901 7.41669V11.3473H10.572V0.545288H12.3901V4.47587C12.7011 4.14929 13.0825 3.89787 13.5052 3.74067C13.9777 3.56728 14.4782 3.4829 14.9813 3.4918C15.4119 3.47133 15.8423 3.5367 16.2473 3.68412C16.6524 3.83154 17.0241 4.05806 17.3408 4.35049"
        fill="currentColor"
      />
      <path
        d="M21.9876 10.9372C21.3715 10.6131 20.8593 10.1219 20.5096 9.51999C20.1606 8.89074 19.9775 8.18304 19.9775 7.46351C19.9775 6.74397 20.1606 6.03628 20.5096 5.40703C20.8613 4.80813 21.3732 4.31929 21.9876 3.99552C22.6486 3.66069 23.3791 3.48621 24.1201 3.48621C24.861 3.48621 25.5916 3.66069 26.2526 3.99552C26.8667 4.3197 27.3785 4.80845 27.7306 5.40703C28.0785 6.03661 28.261 6.74418 28.261 7.46351C28.261 8.18284 28.0785 8.8904 27.7306 9.51999C27.3805 10.1216 26.8684 10.6127 26.2526 10.9372C25.5916 11.272 24.861 11.4465 24.1201 11.4465C23.3791 11.4465 22.6486 11.272 21.9876 10.9372ZM25.7719 9.22743C26.1943 8.73792 26.4267 8.1129 26.4267 7.46636C26.4267 6.81981 26.1943 6.1948 25.7719 5.70529C25.3274 5.27582 24.7334 5.03577 24.1153 5.03577C23.4972 5.03577 22.9033 5.27582 22.4588 5.70529C22.0415 6.1972 21.8124 6.8213 21.8124 7.46636C21.8124 8.11142 22.0415 8.73552 22.4588 9.22743C22.9033 9.6569 23.4972 9.89695 24.1153 9.89695C24.7334 9.89695 25.3274 9.6569 25.7719 9.22743Z"
        fill="currentColor"
      />
      <path
        d="M34.6761 10.9244C34.4468 11.1075 34.1823 11.2413 33.8991 11.3177C33.5838 11.4064 33.2577 11.4505 32.9302 11.4488C32.2081 11.4951 31.4968 11.2548 30.9507 10.7801C30.704 10.5216 30.5138 10.2147 30.3922 9.87884C30.2705 9.54296 30.22 9.18545 30.244 8.82902V5.08841H28.9711V3.6313H30.244V1.85693H32.062V3.6313H34.1517V5.08841H32.062V8.78532C32.0398 9.09841 32.1392 9.408 32.3394 9.64971C32.4439 9.75332 32.5693 9.8335 32.7072 9.88492C32.8451 9.93635 32.9923 9.95784 33.1392 9.94797C33.5058 9.96171 33.866 9.84903 34.1594 9.62881L34.6761 10.9244Z"
        fill="currentColor"
      />
      <path
        d="M37.2824 10.9372C36.6663 10.6131 36.154 10.1219 35.8043 9.51999C35.4554 8.89074 35.2723 8.18304 35.2723 7.46351C35.2723 6.74397 35.4554 6.03628 35.8043 5.40703C36.156 4.80813 36.6679 4.31929 37.2824 3.99552C37.9433 3.66069 38.6739 3.48621 39.4148 3.48621C40.1558 3.48621 40.8863 3.66069 41.5473 3.99552C42.1614 4.3197 42.6732 4.80845 43.0253 5.40703C43.3732 6.03661 43.5557 6.74418 43.5557 7.46351C43.5557 8.18284 43.3732 8.8904 43.0253 9.51999C42.6752 10.1216 42.1631 10.6127 41.5473 10.9372C40.8863 11.272 40.1558 11.4465 39.4148 11.4465C38.6739 11.4465 37.9433 11.272 37.2824 10.9372ZM41.0667 9.22743C41.489 8.73792 41.7214 8.1129 41.7214 7.46636C41.7214 6.81981 41.489 6.1948 41.0667 5.70529C40.6221 5.27582 40.0282 5.03577 39.4101 5.03577C38.792 5.03577 38.198 5.27582 37.7535 5.70529C37.3362 6.1972 37.1072 6.8213 37.1072 7.46636C37.1072 8.11142 37.3362 8.73552 37.7535 9.22743C38.198 9.6569 38.792 9.89695 39.4101 9.89695C40.0282 9.89695 40.6221 9.6569 41.0667 9.22743Z"
        fill="currentColor"
      />
      <path
        d="M55.991 1.59432C56.6068 1.85208 57.1314 2.28806 57.4975 2.84626C57.8406 3.43268 58.0214 4.09985 58.0214 4.77926C58.0214 5.45866 57.8406 6.12583 57.4975 6.71225C57.1332 7.27334 56.6082 7.71161 55.991 7.96988C55.2622 8.27616 54.4769 8.42506 53.6866 8.40683H51.386V11.3476H49.4863V1.15738H53.679C54.4693 1.13994 55.2544 1.28881 55.9834 1.59432H55.991ZM55.4686 6.28101C55.671 6.08734 55.8321 5.85469 55.9421 5.59708C56.0522 5.33947 56.1089 5.06224 56.1089 4.7821C56.1089 4.50197 56.0522 4.22474 55.9421 3.96713C55.8321 3.70952 55.671 3.47687 55.4686 3.2832C54.9224 2.8964 54.2593 2.71047 53.5916 2.75697H51.386V6.80344H53.5992C54.2666 6.8497 54.9294 6.66452 55.4762 6.27911"
        fill="currentColor"
      />
      <path
        d="M65.2637 4.30704C65.5845 4.63112 65.8318 5.02042 65.9888 5.44852C66.1458 5.87662 66.2089 6.33349 66.1737 6.78812V11.3475H64.4639V10.3976C64.2318 10.7459 63.9011 11.0171 63.5141 11.1765C63.0407 11.3676 62.5331 11.4594 62.0228 11.4463C61.4967 11.4573 60.9745 11.3549 60.4916 11.1461C60.0834 10.9698 59.7331 10.6821 59.4809 10.316C59.2435 9.96099 59.1196 9.54228 59.1256 9.11531C59.1147 8.79384 59.1793 8.47428 59.3143 8.18229C59.4492 7.89031 59.6507 7.63403 59.9026 7.43403C60.6259 6.95374 61.4898 6.7309 62.3552 6.80142H64.3651V6.68553C64.38 6.45359 64.344 6.22121 64.2596 6.00464C64.1753 5.78806 64.0447 5.59253 63.8769 5.43169C63.4649 5.11226 62.9492 4.95662 62.4293 4.99475C61.9926 4.99468 61.5586 5.06328 61.1432 5.19803C60.7533 5.31733 60.3901 5.51085 60.0736 5.76795L59.3593 4.43812C59.801 4.11154 60.2996 3.86997 60.8297 3.72572C61.4218 3.55843 62.0344 3.4753 62.6497 3.47875C63.5977 3.41317 64.5357 3.70684 65.277 4.30134L65.2637 4.30704ZM63.6186 9.81062C63.9659 9.60967 64.2316 9.29292 64.369 8.91584V8.01345H62.4901C61.4427 8.01345 60.919 8.35794 60.919 9.04692C60.9142 9.20035 60.9476 9.35257 61.0162 9.48987C61.0849 9.62717 61.1866 9.74523 61.3123 9.83342C61.6352 10.0446 62.0175 10.1465 62.4027 10.1241C62.8287 10.1321 63.2489 10.0244 63.6186 9.81252"
        fill="currentColor"
      />
      <path
        d="M73.0001 3.48556V5.21624C72.8605 5.18882 72.7187 5.17419 72.5765 5.17254C72.2722 5.15527 71.9677 5.2014 71.6822 5.30801C71.3967 5.41462 71.1365 5.57939 70.918 5.79186C70.4916 6.28452 70.2772 6.9257 70.3215 7.57573V11.3448H68.5034V3.57295H70.2341V4.7128C70.5485 4.28554 70.9692 3.94798 71.4544 3.73368C71.9397 3.51939 72.4726 3.43581 73.0001 3.49126"
        fill="currentColor"
      />
      <path
        d="M79.5063 10.9244C79.277 11.1075 79.0125 11.2413 78.7293 11.3177C78.414 11.4064 78.0879 11.4505 77.7604 11.4488C77.0383 11.4951 76.327 11.2548 75.7809 10.7801C75.5362 10.5211 75.3479 10.2141 75.2279 9.8786C75.1079 9.54311 75.0588 9.18636 75.0837 8.83092V5.08841H73.8013V3.6313H75.0837V1.85693H76.9017V3.6313H78.9914V5.08841H76.9017V8.78532C76.8795 9.09841 76.9789 9.408 77.1791 9.64971C77.2836 9.75332 77.409 9.8335 77.5469 9.88492C77.6848 9.93635 77.832 9.95784 77.9789 9.94797C78.3455 9.96171 78.7057 9.84903 78.999 9.62881L79.5063 10.9244Z"
        fill="currentColor"
      />
      <path
        d="M87.7759 4.34546C88.0904 4.68667 88.3314 5.08888 88.484 5.52711C88.6366 5.96534 88.6976 6.43026 88.6631 6.89302V11.346H86.845V7.12669C86.8862 6.57248 86.7143 6.02343 86.3644 5.59169C86.1858 5.41338 85.9712 5.27509 85.7351 5.186C85.4989 5.09692 85.2466 5.05906 84.9947 5.07496C84.7028 5.05967 84.4109 5.10551 84.1377 5.20954C83.8646 5.31357 83.6161 5.4735 83.4084 5.67908C82.9919 6.15946 82.7823 6.78496 82.8251 7.41925V11.3498H81.0071V3.57416H82.7397V4.57722C83.0459 4.21492 83.4376 3.93479 83.8795 3.76223C84.371 3.5712 84.8948 3.47702 85.4221 3.48487C85.8527 3.4644 86.283 3.52977 86.6881 3.67719C87.0932 3.82461 87.4649 4.05112 87.7816 4.34356"
        fill="currentColor"
      />
      <path
        d="M98.3251 8.04159H92.2269C92.3155 8.58793 92.6158 9.07735 93.0628 9.40371C93.5532 9.75072 94.1437 9.92755 94.7441 9.90714C95.1351 9.91968 95.5244 9.85122 95.8876 9.70606C96.2508 9.5609 96.5801 9.34219 96.8547 9.06365L97.8293 10.1845C97.473 10.6079 97.0156 10.9346 96.4995 11.1344C95.8438 11.3743 95.1469 11.4811 94.4495 11.4485C93.7521 11.4159 93.0682 11.2445 92.4378 10.9444C91.8105 10.6256 91.2882 10.1329 90.9332 9.52529C90.5728 8.89963 90.389 8.18789 90.4013 7.46596C90.3903 6.75095 90.5686 6.04574 90.918 5.42183C91.2545 4.82433 91.7513 4.3328 92.3523 4.00272C92.9858 3.65745 93.6979 3.48205 94.4193 3.49358C95.1284 3.48205 95.8284 3.65556 96.4501 3.99702C97.0402 4.32534 97.5245 4.81495 97.8464 5.40853C98.1914 6.05493 98.3647 6.77902 98.3498 7.51155C98.3498 7.6382 98.3403 7.81742 98.3213 8.04919L98.3251 8.04159ZM92.9431 5.46552C92.5371 5.81756 92.2758 6.30757 92.2098 6.84094H96.6154C96.5564 6.31223 96.3024 5.82451 95.903 5.47312C95.4915 5.11866 94.962 4.93154 94.4193 4.94879C93.8801 4.93307 93.354 5.11632 92.9412 5.46362"
        fill="currentColor"
      />
      <path
        d="M104.606 3.48556V5.21624C104.466 5.18882 104.325 5.17419 104.182 5.17254C103.878 5.15527 103.574 5.2014 103.288 5.30801C103.003 5.41462 102.742 5.57939 102.524 5.79186C102.098 6.28452 101.883 6.9257 101.927 7.57573V11.3448H100.109V3.57295H101.84V4.7128C102.154 4.28554 102.575 3.94798 103.06 3.73368C103.546 3.51939 104.078 3.43581 104.606 3.49126"
        fill="currentColor"
      />
    </svg>
  );
}
